var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-5"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Cov-Cares Support Tasks")])],1),(_vm.tasks.length === 0)?_c('v-card-text',[_c('p',[_vm._v("You currently have no assigned tasks.")])]):_vm._e(),_c('v-list',_vm._l((_vm.tasks),function(ref){
var _id = ref._id;
var student = ref.student;
var alertType = ref.alertType;
var status = ref.status;
var due = ref.due;
var text = ref.text;
var added = ref.added;
var comments = ref.comments;
var closed = ref.closed;
var photo = ref.photo;
return _c('v-list-item',{key:_id},[(photo)?_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":photo}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Student: "+_vm._s(student.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.bannerId))]),_c('v-list-item-subtitle',[_vm._v("Class Level: "+_vm._s(student.classLevel || 'FR'))])],1),_c('v-list-item-content',{staticStyle:{"flex-grow":"2"}},[_c('v-alert',{staticClass:"mt-1 mb-1",attrs:{"type":alertType,"text":"","elevation":"2"},scopedSlots:_vm._u([(status !== 'Closed')?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-7",attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.viewComments({ _id: _id, student: student, comments: comments })}}},on),[_c('v-icon',[_vm._v(_vm._s(comments.length === 0 ? 'fal' : 'fas')+" fa-comment-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Comment to Student's Timeline")])]),_c('v-btn',{on:{"click":function($event){return _vm.startClose(_id)}}},[_vm._v("Close Task")])]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(text)+" "),_c('div',{staticStyle:{"font-size":".8em"}},[_vm._v("Assigned on "+_vm._s(_vm.stringFormatDate(added)))]),(status === 'Closed')?_c('div',{staticStyle:{"font-size":".8em"}},[_vm._v("Closed by "+_vm._s(closed.name)+" on "+_vm._s(_vm.stringFormatDate(closed.date)))]):(due != null && due !== '')?_c('div',{staticStyle:{"font-size":".8em"}},[(alertType === 'error')?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("OVERDUE")]):(alertType === 'warning')?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("DUE SOON")]):_vm._e(),_vm._v(" Due "+_vm._s(_vm.stringFormatDate(due).replace(' at ', ' by ')))],1):_vm._e()])],1)],1)}),1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.commentWindow),callback:function ($$v) {_vm.commentWindow=$$v},expression:"commentWindow"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#193264","dark":""}},[_c('v-toolbar-title',[_vm._v("Comments for "+_vm._s(_vm.commentName))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.commentWindow = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-list',_vm._l((_vm.commentComments),function(ref,index){
var user = ref.user;
var text = ref.text;
var date = ref.date;
return _c('v-list-item',{key:'comment-' + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user)+" added comment on "+_vm._s(_vm.stringFormatDate(date))+":")]),_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s('<p>' + text.replace('\n', '</p><p>') + '</p>')}}),(index < _vm.commentComments.length - 1)?_c('v-divider'):_vm._e()],1)],1)}),1),_c('v-card-text',[_c('v-textarea',{attrs:{"label":"New Comment","rows":"4","outlined":"","hide-details":""},model:{value:(_vm.newComment),callback:function ($$v) {_vm.newComment=$$v},expression:"newComment"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.newComment === ''},on:{"click":function($event){return _vm.addComment()}}},[_vm._v("Add Comment")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.commentWindow = false}}},[_vm._v("Close Comment Window")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.closeTaskWindow),callback:function ($$v) {_vm.closeTaskWindow=$$v},expression:"closeTaskWindow"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#193264","dark":""}},[_c('v-toolbar-title',[_vm._v("Close Task")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.closeTaskWindow = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"mt-3"},[_vm._v("Please explain what you did to complete this task and/or the outcome of this task.")]),_c('v-textarea',{attrs:{"label":"Resolution/Outcome","rows":"4","outlined":"","hide-details":""},model:{value:(_vm.closeComment),callback:function ($$v) {_vm.closeComment=$$v},expression:"closeComment"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.closeComment === ''},on:{"click":function($event){return _vm.closeTask()}}},[_vm._v("Close Task")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.closeTaskWindow = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }