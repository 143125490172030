<template>
  <v-card class="ma-5">
    <v-toolbar>
      <v-toolbar-title>Cov-Cares Support Tasks</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="tasks.length === 0">
      <p>You currently have no assigned tasks.</p>
    </v-card-text>
    <v-list>
      <v-list-item v-for="{_id, student, alertType, status, due, text, added, comments, closed, photo } of tasks" :key="_id">
        <v-list-item-avatar v-if="photo">
          <v-img :src="photo"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Student: {{ student.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ student.bannerId }}</v-list-item-subtitle>
          <v-list-item-subtitle>Class Level: {{ student.classLevel || 'FR' }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content style="flex-grow: 2">
          <v-alert :type="alertType" text elevation="2" class="mt-1 mb-1">
            {{ text }}
            <div style="font-size:.8em">Assigned on {{ stringFormatDate(added) }}</div>
            <div v-if="status === 'Closed'" style="font-size:.8em">Closed by {{ closed.name }} on {{ stringFormatDate(closed.date) }}</div>
            <div v-else-if="due != null && due !== ''" style="font-size:.8em">
              <v-chip v-if="alertType === 'error'" small label>OVERDUE</v-chip>
              <v-chip v-else-if="alertType === 'warning'" small label>DUE SOON</v-chip>
              Due {{ stringFormatDate(due).replace(' at ', ' by ') }}</div>
            <template v-if="status !== 'Closed'" v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="info" class="mr-7" @click="viewComments({ _id, student, comments })">
                    <v-icon>{{ comments.length === 0 ? 'fal' : 'fas' }} fa-comment-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Comment to Student's Timeline</span>
              </v-tooltip>
              <v-btn @click="startClose(_id)">Close Task</v-btn>
            </template>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="commentWindow" width="800">
      <v-card>
        <v-toolbar color="#193264" dark>
          <v-toolbar-title>Comments for {{ commentName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="commentWindow = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list>
          <v-list-item v-for="({ user, text, date }, index) of commentComments" :key="'comment-' + index">
            <v-list-item-content>
              <v-list-item-title>{{ user }} added comment on {{ stringFormatDate(date) }}:</v-list-item-title>
              <v-list-item-subtitle style="white-space: normal;" v-html="'<p>' + text.replace('\n', '</p><p>') + '</p>'"></v-list-item-subtitle>
              <v-divider v-if="index < commentComments.length - 1"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text>
          <v-textarea v-model="newComment" label="New Comment" rows="4" outlined hide-details></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="newComment === ''" @click="addComment()">Add Comment</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="commentWindow = false">Close Comment Window</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="closeTaskWindow" width="600">
      <v-card>
        <v-toolbar color="#193264" dark>
          <v-toolbar-title>Close Task</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeTaskWindow = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="mt-3">Please explain what you did to complete this task and/or the outcome of this task.</p>
          <v-textarea v-model="closeComment" label="Resolution/Outcome" rows="4" outlined hide-details></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="closeComment === ''" @click="closeTask()">Close Task</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeTaskWindow = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const tasks = ref([])
    onMounted(async () => {
      const query = {
        status: { $ne: 'Clozed' },
        // 'assignee.pidm': user.value.pidm,
        $sort: { due: 1 }
      }
      const { data } = await root.$feathers.service('cov-cares/task').find({ query })
      for (let i = 0; i < data.length; i++) {
        let alertType = 'info'
        const dt = new Date()
        const due = new Date(data[i].due)
        if (data[i].status === 'Closed') alertType = 'success'
        else if (due < dt) alertType = 'error'
        else {
          dt.setDate(dt.getDate() + 14)
          if (due < dt) alertType = 'warning'
        }
        const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: data[i].student.pidm } })
        if (dirData.length > 0) {
          data.splice(i, 1, { ...data[i], photo: dirData[0].photo, alertType })
        } else {
          data.splice(i, 1, { ...data[i], alertType })
        }
      }
      tasks.value = data
    })

    const commentWindow = ref(false)
    const commentId = ref('')
    const commentName = ref('')
    const commentComments = ref([])
    function viewComments ({ _id, student, comments }) {
      commentId.value = _id
      commentName.value = student.name
      commentComments.value = comments
      commentWindow.value = true
    }

    const newComment = ref('')
    async function addComment () {
      const { comments } = await root.$feathers.service('cov-cares/task').patch(commentId.value, { $push: { comments: { user: user.value.username, text: newComment.value, date: new Date() } } })
      for (let i = 0; i < tasks.value.length; i++) {
        if (tasks.value[i]._id === commentId.value) {
          tasks.value.splice(i, 1, { ...tasks.value[i], comments })
          break
        }
      }
      commentWindow.value = false
      newComment.value = ''
    }

    const closeTaskWindow = ref(false)
    const closeId = ref('')
    function startClose (id) {
      closeId.value = id
      closeTaskWindow.value = true
    }
    const closeComment = ref('')
    async function closeTask () {
      const { comments, closed } = await root.$feathers.service('cov-cares/task').patch(closeId.value, {
        $push: { comments: { user: user.value.username, text: closeComment.value, date: new Date() } },
        status: 'Closed',
        closed: { pidm: user.value.pidm, name: user.value.name, date: new Date() }
      })
      for (let i = 0; i < tasks.value.length; i++) {
        if (tasks.value[i]._id === commentId.value) {
          tasks.value.splice(i, 1, { ...tasks.value[i], comments, status: 'Closed', closed })
          break
        }
      }
    }

    return {
      user,
      tasks,
      commentWindow,
      commentName,
      commentComments,
      viewComments,
      newComment,
      addComment,
      closeTaskWindow,
      closeId,
      startClose,
      closeComment,
      closeTask,
      stringFormatDate
    }
  }
}
</script>
